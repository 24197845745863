@media (max-width: 1188px) {
  .payment-stepper-cart {
    width: 80%;
  }
}

@media (max-width: 1173px) {
  .payment-stepper-cart {
    width: 90%;
  }

  .quickvee-payment-component-content {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1035px) {
  .payment-stepper-cart {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .payment-stepper-cart {
    display: flex !important;
    flex-wrap: wrap;
  }

  .payment-stepper-cart > .payment-stepper-wishlist-item {
    width: 100%;
  }

  .payment-stepper-cart > .payment-stepper-checkout-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .payment-stepper-cart > .payment-stepper-customer-detail-section {
    width: 100%;
  }

  .payment-all-cart-items.overflowed {
    padding: 0;
    overflow: hidden;
  }

  .quickvee-payement-stepper > span {
    font-size: clamp(12px, 2px + 2.1783vw, 18px) !important;
  }

  .quickvee-payement-stepper {
    padding: 20px 0px;
    /* margin-bottom: 20px; */
  }

  .payment-stepper-cart
    > .payment-stepper-wishlist-item
    > .payment-all-cart-items {
    height: unset !important;
    max-height: unset !important;
  }

  .payment-stepper-cart > .payment-stepper-customer-detail-section {
    border-right: 0;
  }

  /* .custom-file-upload-input {
        width: 90% !important;
    } */
  .payment-delivery-payment-info-section {
    height: unset;
  }

  .payment-stepper-cart > .payment-stepper-wishlist-item {
    border-right: 0;
  }

  #modal-modal-description {
    overflow-x: unset;
  }

  .payment-stepper-cart > .payment-stepper-customer-detail-section {
    padding: 0;
  }

  .payment-delivery-section {
    margin-bottom: 20px;
  }
  .payment-stepper-checkout-item {
    padding-top: 10px !important;
  }

  .payment-stepper-customer-detail-section {
    padding: 0 20px !important;
  }

  .payment-stepper-cart
    > .payment-stepper-wishlist-item
    > .payment-all-cart-items {
    margin: 0 !important;
  }

  .payment-stepper-single-billing-info-detail {
    padding-top: 20px;
  }

  .payment-delivery-payment-info-section {
    margin-bottom: 10px;
  }

  .profile-page-user-data-button {
    padding-left: 11px;
  }

  .payment-card-details-add-section > div {
    /* width: 100% !important; */
    margin-bottom: 15px;
  }

  .card-expiry-date {
    max-width: 100% !important;
    bottom: -17px !important;
  }
}

/* add css by priya */
.MuiPaper-root::-webkit-scrollbar {
  width: 3px;
}
.MuiPaper-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;

  cursor: pointer;
}

/* Handle */
.MuiPaper-root::-webkit-scrollbar-thumb {
  background: #3e85f8;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  outline: 1px solid #fff;
}

/* Handle on hover */
.MuiPaper-root::-webkit-scrollbar-thumb:hover {
  background: #0a64f9;
  cursor: pointer !important;
}

@media (max-width: 604px) {
  .payment-card-details-add-section > div {
    width: 100% !important;
  }
}

@media (max-width: 480px) {
  .top-content p {
    font-family: QuicksandBold !important;
    font-size: 16px;
    font-weight: 400 !important;
  }

  /* .payment-card-details-add-section>div{
        width: 100% !important;
        margin-bottom: 15px;
    } */

  /* .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
        padding: 10px 10px !important;
    } */

  .MobileViewPaymentWishlist-option-title {
    font-weight: 500 !important;
    padding-left: 5px;
    margin-bottom: 5px;
  }

  .payment-stepper-cart > .payment-stepper-wishlist-item {
    width: 100%;
  }

  /* font-size: clamp(12px, 0.05px + 5.1783vw, 20px) !important; */

  .payment-stepper-cart > .payment-stepper-checkout-item {
    width: 100%;
    margin-bottom: 20px;
    /* box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px; */
  }

  .payment-stepper-cart > .payment-stepper-wishlist-item {
    padding: 0;
  }

  .payment-page-wishlist-cart-items {
    margin-top: 5px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }

  .quickvee-payment-component-content {
    padding: 0;
  }

  .payment-page-wishlist-cart-items {
    border-radius: 0;
    border: 0;
  }

  .id-card-detail-image {
    width: 100% !important;
  }

  .custom-file-upload-input {
    width: 100% !important;
  }

  .payment-billing-inputs-div {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .payment-billing-input-outer-div {
    width: 100%;
  }

  .payment-billing-city-state-update-inputs {
    display: flex !important;
    gap: 2 !important;
  }

  .payment-billing-city-state-update-inputs > div:first-child {
    margin-right: 10px;
    width: 100%;
  }

  .payment-billing-input-outer-div {
    position: relative;
    margin-top: 0px !important;
    margin-bottom: 15px;
  }

  .quickvee-payement-stepper > span {
    margin: 0 5px;
  }

  .css-10botns-MuiInputBase-input-MuiFilledInput-input {
    padding-top: 20px;
  }
}

.payment-page-wishlist-item-content > .middle-content {
  width: 100% !important;
}

.payment-page-wishlist-item-content
  > .middle-content
  > .increment-decremnt-button
  > span {
  margin: unset;
}

.payment-page-wishlist-item-content
  > .middle-content
  > .increment-decremnt-button
  button:disabled {
  background: #80aef7;
}

.payment-page-wishlist-item-content
  > .middle-content
  > .increment-decremnt-button
  button {
  border: none;
  background: #0a64f9;
  border-radius: 4px;
  color: #fff;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.payment-page-wishlist-item-content > .bottom-dropdowns-for-categories > div {
  margin-bottom: 10px !important;
  padding: 0px 3px;
  margin-right: 0;
  width: unset() !important;
  padding: 0 5px !important;
}

.billing-info-delivery-address-update-form > div:nth-child(2) {
  margin: 0px 0px !important;
}

.payment-delivery-address-section
  > .bottom-section-of-payment-delivery-address
  > .payment-delivery-single-delivery-address {
  margin: 4px -4px !important;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-item-top {
  font-size: clamp(12px, 0.05px + 4.1783vw, 20px) !important;
}

.payment-billing-customer-form > h1 {
  margin-bottom: 12px;
  font-size: clamp(12px, 0.05px + 4.1783vw, 20px) !important;
}

.payment-delivery-section > span {
  font-size: clamp(12px, 0.05px + 4.1783vw, 20px) !important;
}

.payment-delivery-payment-info-section > span {
  font-size: clamp(12px, 0.05px + 4.1783vw, 20px) !important;
}

.payment-delivery-address-section
  > .head-section-of-payment-delivery-address
  > span {
  font-size: clamp(12px, 0.05px + 4.1783vw, 20px) !important;
}

.payment-radio-heading span:nth-child(2) {
  font-size: clamp(12px, 0.05px + 4.1783vw, 16px) !important;
}

.css-ahj2mt-MuiTypography-root {
  font-size: clamp(12px, 0.05px + 3.1783vw, 16px) !important;
}

.modal-main-button {
  font-size: clamp(12px, 0.05px + 3.1783vw, 14px) !important;
}

.select-delivery-hour-and-date label {
  font-size: clamp(14px, 0.05px + 3.1783vw, 14px) !important;
}

.modal-form-single-input label {
  font-size: clamp(10px, 0.05px + 3.1783vw, 14px) !important;
}

.css-1ff8729-MuiInputBase-root-MuiFilledInput-root {
  font-size: clamp(14px, 0.05px + 2.6783vw, 14px) !important;
}

.stateDropdown .modal-form-single-input {
  font-size: clamp(10px, 0.05px + 2.6783vw, 14px) !important;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > .quickvee-checkout-final-amount {
  margin: 15px 0 15px 0 !important;
}

.payment-stepper-cart
  > .payment-stepper-wishlist-item
  > .payment-stepper-wishlist-item-topper {
  margin-top: 20px;
}

.payment-stepper-wishlist-item {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .payment-stepper-checkout-item-top {
    margin-top: 20px;
  }
}

.payment-billing-input-outer-div {
  position: relative;
  margin-top: 0px !important;
  margin-bottom: 15px;
}

.input-error {
  position: absolute;
  bottom: -17px;
  left: 10px;
  /* width: 80px; */
  /* padding-right: 30px; */
  height: 15px;
}

.payment-billing-input-outer-div-for-all {
  margin: 0 !important;
}

.top-bar-button {
  padding-left: 19px !important;
  min-width: 0 !important;
  padding: 0 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  padding: 8.76px 0px !important;
  /* height: 44px; */
}

.payment-card-details-add-section-single-item {
  position: relative !important;
}

.cvv {
  position: relative !important;
}

.card-expiry-date {
  position: absolute;
  /* bottom: -30px; */
  left: 0;
  /* font-size: clamp(2px, 0.05px + 2.8783vw, 8px) !important; */
  max-width: 100px;
}

/* @media (max-width: 392px) {
    .cvv-input{
        bottom: -29px;
    }
} */

.choose-date-on-payment {
  position: relative !important;
}

.choose-time-on-payment {
  position: relative !important;
}

.css-1xhypcz-MuiStack-root > .MuiTextField-root {
  /* min-width: unset !important; */
}

.custom-file-upload-input {
  position: relative;
}

.image_input_error {
  left: 0;
}

@media (max-width: 480px) {
  .orderSummery_head {
    padding: 0 15px;
  }

  .OrderSummeryProductDiv {
    padding: 0 20px;
  }

  .OrderSummeryItemDiv p.blackclr {
    background-color: #f1f1f1;
    padding: 10px 20px;
    font-size: 20px;
  }

  .OrderSummeryItemDiv p {
    padding: 0 20px;
  }

  .orderSummeryRightTop {
    padding-left: 20px;
    padding-right: 20px;
  }

  .orderSummeryRightTopC p {
    padding: 0 20px;
  }

  .orderSummeryCustomerDetailsArea .orderSummery_head {
    padding-left: 20px;
    padding-right: 20px;
  }

  .orderSummeryCustomerDetailsInner {
    padding: 0 20px;
  }

  .orderSummeryIdentification h1 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .left-font-header img {
    padding-left: 20px;
    padding-right: 20px;
  }

  .left-font-header {
    border: 0;
  }

  .orderSummeryRightTopC p.amountrow {
    background-color: #f1f1f1;
    padding: 10px 15px;
    border: 0;
    font-size: 20px;
  }
}

.css-1gtlbg0 {
  overflow: unset !important;
  padding-top: 0 !important;
}

.payment-summary-input {
  position: relative !important;
}

.css-m9glnp-MuiPaper-root-MuiDialog-paper {
  height: unset !important;
  width: unset !important;
}

.css-1hyl1h2 {
  height: unset !important;
  width: unset !important;
}

/* .css-1ujsas3{
    min-width: unset !important;
    padding: 0px !important;
} */

.BasicInfo-btn-padding {
  min-width: unset !important;
  padding: 0px !important;
}

.error-on-submit-register {
  left: 0 !important;
}

.payment-modal-button button {
  width: 48%;
  padding: 10px;
  font-size: 15px;
  font-family: QuicksandBold, 'Quicksand' !important;
  background-color: #fff;
  outline: none;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.payment-modal-outer-div .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 500px !important;
}

.payment-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 25px;
}

.payment-modal .payment-modal-content img {
  margin-bottom: 15px;
  width: 80px;
  height: auto;
}

.payment-modal .payment-modal-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
}

.payment-modal .payment-modal-button button {
  width: 48%;
  padding: 10px;
  font-size: 15px;
  font-family: QuicksandBold, 'Quicksand' !important;
  background-color: #fff;
  outline: none;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.payment-modal .payment-modal-button button:first-child {
  border: 1px solid #232323;
}

.payment-modal .payment-modal-content span {
  text-align: center;
  color: #212121;
  font-family: QuicksandBold, 'Quicksand' !important;
  font-size: 18px;
  padding: 0 10px;
}

.payment-modal .out-of-stock-products span {
  font-family: QuicksandBold, 'Quicksand' !important;
  font-size: 16px;
  align-self: start;
  margin-bottom: 5px;
  text-align: left;
}

.payment-modal-outer-div-card .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 370px;
}

.css-uhb5lp {
  width: 370px !important;
}

.DatePicker-Debit-creadit-card {
  width: 100%;
  font-size: 12px !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
  background: #ffffff !important;
  border: 1px solid #d3d3d3 !important;
  border-radius: 6px !important;
  /* height: 48px; */
}

.css-1rgmeur.Mui-error {
  color: unset !important;
}

.css-dvhgws::before {
  border-bottom: unset !important;
}

.css-dvhgws {
  background-color: unset !important;
}

.custom-date-picker-width {
  width: 100% !important;
  min-width: unset !important;
  /* Adjust width as needed */
}

.custom-date-picker-width
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #000 !important;
  border-width: 1px !important;
}
.custom-date-picker-width
  .MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.custom-date-picker-width > div > input {
  font-family: QuicksandBold, 'Quicksand' !important;
}

.custom-date-picker-width > label {
  font-family: QuicksandBold, 'Quicksand' !important;
}

.css-192l38i {
  display: block !important;
}

.input-field {
  width: 100% !important;
  position: relative !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
}

.input-field
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  /* border-color: #000 !important; */
  border-color: #000 !important;
  border-width: 1px !important;
}
.input-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  /* border-color: #000 !important; */
  border-color: #000 !important;
  border-width: 1px !important;
}

.select-OnFocus.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #000 !important;
  border-width: 1px !important;
}

/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color:  #000000 !important;
} */
/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #000000 !important;
  border-width: 1px !important;
} */

.input-field > div > input {
  /* font-family: QuicksandBold, 'Quicksand' !important; */
  font-family: QuicksandSemiBold, 'Quicksand' !important;
}

.input-field > label {
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  color: #000000 !important;
}

.form-select-formcontrol > label {
  font-family: QuicksandSemiBold, 'Quicksand' !important;
}

.form-select-formcontrol > div > div {
  font-family: QuicksandBold, 'Quicksand' !important;
}

.form-select-formcontrol > div > input {
  font-family: QuicksandSemiBold, 'Quicksand' !important;
}

.delivery-payment-radio-button {
  display: flex !important;
  flex-direction: row !important;
}

.MuiPickersLayout-root {
  display: unset !important;
}

.MuiDialog-paper {
  min-width: 280px !important;
  /* width: 70% !important; */
}
@media (max-width: 480px) {
  .MuiDialog-paper {
    width: 70% !important;
  }
}
.MuiDateCalendar-root {
  width: 280px !important;
}
.MuiMonthCalendar-root {
  width: 280px !important;
}
.MuiDialogContent-root {
  overflow-x: hidden;
}
/* Track */
.MuiDialogContent-root::-webkit-scrollbar {
  width: 3px !important;
}
.MuiDialogContent-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;

  cursor: pointer !important;
}

/* Handle */
.MuiDialogContent-root::-webkit-scrollbar-thumb {
  background: #3e85f8 !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  transition: background 0.3s ease-in-out !important;
  outline: 1px solid #fff !important;
}

/* Handle on hover */
.MuiDateCalendar-root::-webkit-scrollbar-thumb:hover {
  background: #0a64f9 !important;
  cursor: pointer !important;
}

.MuiYearCalendar-root {
  width: 280px !important;
}

/* Track */
.MuiYearCalendar-root::-webkit-scrollbar {
  width: 3px;
}
.MuiYearCalendar-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;

  cursor: pointer;
}

/* Handle */
.MuiYearCalendar-root::-webkit-scrollbar-thumb {
  background: #3e85f8;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  outline: 1px solid #fff;
}

/* Handle on hover */
.MuiYearCalendar-root::-webkit-scrollbar-thumb:hover {
  background: #0a64f9;
  cursor: pointer !important;
}

/* .MuiDialogActions-root > button{
  border: 2px solid red !important; 
  background: #ffffff ;
  border: 1px solid #232323 !important;
  border-radius: 6px !important;
  color: #232323 !important;
  text-transform: capitalize !important;
  font-family: QuicksandBold, 'Quicksand' !important;
  width: 48%;
}
.MuiDialogActions-root.second-buttons > button {
  background: #0a64f9 !important;
  border: 2px solid #0a64f9 !important;
  color: #ffffff !important;
} */

.MuiFormLabel-root {
  color: #000 !important;
}

.MuiDialogActions-root {
  justify-content: space-around !important;
}

.topBarUserAndCartLogo > button {
  min-width: unset !important;
  padding: unset !important;
}

@media (min-width: 768px) {
  .loyality-points-para {
    margin-left: 0 !important;
  }
}
@media (max-width: 480px) {
  .loyality-point-para-collpase {
    margin-left: 0 !important;
  }
}
.Log-in-as-Guest-div button {
  width: 325px !important;
}

.common-footer-div {
  margin-left: -47px !important;
  padding-left: 25px !important;
  margin-right: -46px !important;
  padding-right: 26px !important;
}
.common-footer-div > div > div {
  width: 90%;
}

@media (min-width: 768px) {
  .side-bar-and-order-section-footer {
    margin-left: 250px;
  }
}

.home_alert_div h3 {
  font-size: clamp(18px, 0.05px + 2.6783vw, 22px) !important;
}
.home_alert_div p {
  font-size: clamp(14px, 0.05px + 2.6783vw, 18px) !important;
}
.single-store-wishlist {
  /* min-height: calc(100vh - 137px) !important;  */
}

.lodding-image {
  width: 120px;
}

@media (max-width: 768px) {
  .lodding-image {
    width: 110px !important;
  }
}
@media (max-width: 480px) {
  .lodding-image {
    width: 100px !important;
  }
}

.order-status-svg > svg {
  width: 100% !important;
  height: 100% !important;
}

@media (max-width: 480px) {
  .moreordersummary-container {
    background-color: #f1f1f1 !important;
  }
  .moreordersummary-container {
    padding: 0px 18px 0px 0px !important;
  }
}

.loaderarea {
  height: 80vh;
  display: flex;
  align-items: center;
}

@media (max-width: 480px) {
  .refund-orderSummeryPage {
    margin-left: 15px;
    margin-left: 15px;
  }
}

.fa-lg {
  line-height: 0em !important;
}
